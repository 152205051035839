.table-wrapper {
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
}

.table-title {
  margin-top: 120px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 16px;
  text-align: left;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  background-color: #1e1f26;
  color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.styled-table thead tr {
  background-color: #5ca399;
  color: #ffffff;
  font-weight: bold;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #2a2b38;
}

.styled-table tbody tr {
  border-bottom: 1px solid #2a2b38;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #2a2b38;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #5ca399;
}

.styled-table tbody tr:hover {
  background-color: #333344;
  cursor: pointer;
}

.MainActionTable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.action-button {
  background-color: #5ca399;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.action-button:hover {
  background-color: #36606a;
}

.Delete-action-button {
  background-color: #f44336;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.Delete-action-button:hover {
  background-color: #d32f2f;
}

.Restore-action-button {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.Restore-action-button:hover {
  background-color: #3e8e41;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
  background-color: #2c2f3d;
  border-radius: 12px;
  padding: 40px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.2);
  animation: modalContentFadeIn 0.3s ease-in-out;
  color: #ffffff;
}

.modal-content h3 {
  font-size: 22px;
  margin-bottom: 25px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.5px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 25px;
}

.modal-actions button {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 45%;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
}

.confirm-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #e53935;
}

.modal-actions button:hover {
  opacity: 0.9;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalContentFadeIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .styled-table {
    font-size: 14px;
  }

  .action-button {
    padding: 6px 10px;
  }
}
