.images_popupMain {
  height: 100%;
  width: 100%;
  background-color: #00000082;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImageContainer {
  width: 30%;
}

.ImageContainer img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .ImageContainer {
    width: 90%;
  }
}
