@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap'); */
:root {
  --primary-color: #6ea9a1;
  --primary-color-hover: #6ea9a160;
  --secondary-color: #171821;
  --dark-color: #202020;
  --light-color: rgba(210, 210, 210, 0.5);
  --font-family: "Roboto", sans-serif;
  --font-heading: "Roboto", sans-serif;
  --toastify-font-family: "Roboto", sans-serif !important;
  --toastify-font-size: 12px !important;
}
button {
  border: inherit;
}
.cursor {
  cursor: pointer;
}
.relative {
  position: relative;
}
.popup_outer {
  position: fixed;
  z-index: 99;
  background: rgb(55 55 55 / 77%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}
.popup_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.popup_white_center {
  background: #171821;
  width: 38%;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 7px;
}
.modal_heading {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 18px;
  color: #a3a3a3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.close_icon {
  cursor: pointer;
}
.toast_class {
  font-size: 12px !important;
  font-weight: 400;
}
#root {
  height: 100%;
}
html {
  margin: 0;
  padding: 0;
  height: 100%;
}
.wd100 {
  width: 100% !important;
}
.bg_white {
  background: #fff;
}
.font-12 {
  font-size: 12px;
}
body {
  font-family: var(--font-family);
  font-size: 12px;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #171821;
  overflow-x: hidden;
  color: #d2d2d2;
}
.text-secondary {
  color: var(--secondary-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  letter-spacing: 1px;
  margin-bottom: 0px;
  margin-top: 0;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-space-between-stretch {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.flex-start {
  display: flex;
  justify-content: start;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gap_20 {
  gap: 20px;
}
.gap_15 {
  gap: 15px;
}
.gap_10 {
  gap: 10px;
}
.gap_25 {
  gap: 25px;
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
a:hover {
  color: var(--light-color);
  text-decoration: none;
}
.link_class {
  color: var(--light-color);
  text-decoration: none;
  display: block;
}
.link_class:hover {
  color: var(--primary-color);
  text-decoration: none;
}
*:focus {
  border: 1px solid #ccc;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.1),
    0 5px 10px -20px rgba(0, 0, 0, 0.05);
  outline: none;
}
a:focus {
  border: none;
  outline: none;
  box-shadow: none !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.container {
  width: 100%;
  height: 100%;
}
.wrapper {
  width: 1170px;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}
.wrapper_fluid {
  width: 90%;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}
.wrapper_full {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  height: 100%;
}
.flex_center_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.margin_auto {
  margin: auto;
}
.mt-10px {
  margin-top: 10px;
}
.mt-20px {
  margin-top: 20px;
}
.mt-10p {
  margin-top: 10%;
}
.mt-5p {
  margin-top: 5%;
}
.mt-20 {
  margin-top: 20px;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.w50 {
  width: 50%;
}
.w40 {
  width: 40%;
}
.w35 {
  width: 35%;
  max-width: 450px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
.p25 {
  padding: 25px;
}
.p30 {
  padding: 30px;
}
.p40 {
  padding: 40px;
}
.card {
  background: #26273384;
  border: 1px solid #26273384;
  /* box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.1), */
  /* 0 5px 40px -20px rgba(0, 0, 0, 0.05); */
  border-radius: 10px;
  float: left;
  box-sizing: border-box;
}
.card_r {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.1),
    0 5px 40px -20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  float: left;
}

.m-auto-60 {
  width: 65%;
  margin: auto;
}

input,
textarea,
select {
  font-size: 13px;
  color: var(--light-color);
}
.form-group {
  margin-bottom: 8px;
  width: 100%;
}
.form-control {
  border-radius: 8px;
  background: #424350;
  height: 34px;
  border: 1px solid #424350;
  padding: 5px 12px;
  width: 100%;
  box-sizing: border-box;
}
.custom_button {
  background: var(--primary-color);
  color: rgba(225, 222, 222, 0.8);
  text-align: center;
  padding: 8px 10px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  font-size: 12px;
  box-sizing: border-box;
}
.custom_bottom_margin {
  margin-bottom: 65px;
}
.left_bar_fixed {
  background: transparent;
  border-right: 1px solid #87888c;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 18%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  padding: 15px 10px;
  box-sizing: border-box;
  overflow: auto;
}
.dark_mode {
  border-radius: 30px;
  background: #1b1c25;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
  border: none !important;
}
.dark_mode a {
  display: flex;
  align-items: center;
  gap: 13px;
  color: #808080;
  border-radius: 30px;
  justify-content: center;
  width: 50%;
  padding: 5px 12px;
}
.dark_mode_active {
  display: flex;
  align-items: center;
  gap: 13px;
  color: #808080 !important;
  border-radius: 30px;
  justify-content: center;
  background: #fff !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  width: 50%;
  padding: 5px 12px;
}

.dashboard_logo img {
  height: 35px;
  margin-bottom: 45px;
}
.navigation {
  width: 75%;
  margin: auto;
}
.navigation .heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  font-size: 13px;
  color: #87888c;
  font-weight: 600;
  margin-bottom: 10px;
}
.sub_nav {
  margin-left: 24px;
}
.sub_nav .inner_nav,
.nav_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  font-size: 13px;
  color: #87888c;
}
.sub_nav .inner_nav:hover {
  color: var(--primary-color);
}
.main-navigation .nav_main:hover,
.bottom-nav .nav_main:hover {
  color: #fff;
}
.main-navigation .nav_main:hover img,
.bottom-nav .nav_main:hover img {
  filter: brightness(0) invert(1) grayscale(1);
}
.main-navigation {
  border-top: 1px solid #87888c;
  border-bottom: 1px solid #87888c;
  margin-top: 60px;
  margin-left: 24px;
  margin-bottom: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.bottom-nav {
  margin-left: 24px;
}

.nav_main {
  background: transparent;
  border-radius: 5px;
  padding: 15px 18px;
  margin-top: 7px !important;
  font-size: 13px;
  font-weight: 600;
}
.nav_main_active {
  background: #a9e0d8;
  color: #87888c !important;
}

.nav_main_active img {
  filter: none !important;
}
.hedaer_title {
  color: #a0a0a0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 16px;
}
.header_fixed {
  padding: 15px 15px 10px 15px;
}
.right_side_bar {
  margin-left: 18%;
  box-sizing: border-box;
  margin-right: 10px;
}
.mobile_view {
  display: none;
}
.image_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.create_button {
  min-width: 137px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #21222d;
  border: inherit;
  cursor: pointer;
}
.create_button:hover {
  background: #3a3b46;
}
.create_button span {
  color: #d2d2d2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.right_margin_button {
  margin-right: 6px;
}
.search_header {
  position: relative;
}
.search_bar {
  width: 504px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #21222d;
  padding: 10px 20px 10px 35px;
  border: inherit;
}
.search_icon {
  position: absolute;
  left: 15px;
  top: 15px;
}

.content_inner {
  padding-left: 15px;
  margin-top: 15px;
}
.content_settings {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.left_setting_bar {
  padding-left: 25px;
  margin-top: 25px;
  width: 16%;
}
.right_settings_box {
  width: 75%;
}
.setting_bar {
  color: #afaaaa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  margin-bottom: 18px;
}

.setting_bar:hover {
  color: var(--primary-color);
}
.setting_active {
  color: var(--primary-color);
  font-weight: 600;
}

.form-group label {
  color: #4c535f;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
  display: block;
}
.image_browse_button {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 18px;
  border: 1px dashed #4c535f;
  background: #21222d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #4c535f;
  gap: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.image_browse_button:hover {
  border: 1px dashed var(--primary-color);
  background: #21222d80;
}

.form-group .setting_input {
  border-radius: 8px;
  border: 1px solid #e0e4ec;
  background: #21222d;
  padding: 13px;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
}

.form-group .detaileds_input {
  border-radius: 8px;
  border: 1px solid #e0e4ec6d;
  background: #21222d;
  padding: 13px;
  height: 35px;
  width: 100%;
  box-sizing: border-box;
}

.textarea_input {
  font-family: var(--font-family);
  height: 130px !important;
}
.double_in_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.double_in_row .form-group {
  width: 49%;
}
.right_settings_box .form-group {
  margin-bottom: 15px;
}
.custom-mar-textarea {
  margin-top: 10px;
}

.button_submit {
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  min-width: 156px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #7fcbc4;
  border: inherit;
  cursor: pointer;
}
.button_reset {
  color: #4c535f;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  background: transparent;
  border: inherit;
  line-height: normal;
  margin-left: 40px;
}

.tabs_show {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  margin-bottom: 30px;
}
.tabs_show .tabs {
  color: #717b8c;
  font-style: normal;
  line-height: normal;
  border-bottom: 2px solid #e0e4ec;
  padding: 0 10px 7px;
  cursor: pointer;
}

.tabs_show .tabs:hover {
  color: var(--primary-color) !important;
  line-height: normal;
  border-bottom: 2px solid var(--primary-color) !important;
}
.active_tab {
  color: var(--primary-color) !important;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid var(--primary-color) !important;
}

.table_wrap {
  border-collapse: separate;
  border-spacing: 0px;
}

.table_wrap th {
  color: #717175;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  padding-bottom: 20px;
}
.table_wrap th:last-child {
  width: 50px;
}
.table_wrap td {
  color: #a0a0a0;
  text-align: left;
  font-style: normal;
  line-height: normal;
  padding: 10px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.06);
  padding-bottom: 15px;
}
.table_wrap tr:last-child td {
  border-bottom: none !important;
  /* padding-bottom: 0px; */
}
.black_table {
  border-radius: 10px;
}
.black_table td,
.black_table th {
  text-align: center;
}
.black_table td span {
  display: inline-block;
}
.black_table tr:last-child td {
  padding-bottom: 15px !important;
}
.wrap_flex_td {
  display: flex;
  align-items: center;
  gap: 10px;
}
.delete_icon_color {
  color: #ac7676;
  cursor: pointer;
}
.active_status {
  color: green;
  font-weight: 600;
}
.inactive_status {
  color: red;
  font-weight: normal;
}
.table_contact_marg {
  margin-top: 7px;
  display: inline-block;
  line-height: 16px;
}

.image_upload_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.inline_three_inputs {
  clear: both;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.button_bulk {
  float: right;
  clear: both;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.form_setcion {
  clear: both;
}

.edit_link {
  color: #3699ff;
  font-size: 11.632px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
}

.tabs_green {
  min-width: 133px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 3px 3px 0px 0px;
  background: rgba(110, 169, 161, 0.5);
  border: inherit;
  color: rgba(225, 222, 222, 0.5);
  line-height: normal;
  margin-left: 6px;
  font-size: 12px;
  cursor: pointer;
  padding: 0 15px;
}
.tabs_green:hover {
  background: var(--primary-color);
  color: #fff;
}

.active_green {
  background: var(--primary-color) !important;
  color: #fff !important;
}
.blue_link {
  color: #5780be;
  cursor: pointer;
}

.dots_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dots_outer .dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: relative;
  width: 20%;
}
.dots::after {
  content: "";
  position: absolute;
  top: 25%;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 2px;
  background-color: #fff;
  z-index: -1;
}
.dots_outer .dots:last-child {
  margin-right: -30px;
}

.dots_outer .dots:last-child::after {
  content: none;
}

.graph_view {
  width: 50%;
  margin: auto;
}

.left_order_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}
.box_order_inner {
  color: #87888c;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px;
  width: 36%;
  display: flex;
  justify-content: flex-start;
}
.box_order_inner .heading_order {
  text-align: right;
  width: 125px;
  display: inline-block;
  margin-right: 20px;
  font-size: 13px;
}
.flex_wrap_box_order_vendor .left_order_box .box_order_inner {
  width: 100%;
  margin-bottom: 4px;
}

.flex_wrap_box_order_vendor {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flex_wrap_box_order_vendor .left_order_box {
  width: 36%;
}
.lime_color {
  color: #bbc82a;
}

.light_bar_order {
  background: #3c3d5145;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: -30px !important;
  margin-right: -30px;
}
.light_bar_order .left_order_box {
  margin-top: 0;
  padding: 15px 0;
}

.button_followup {
  width: 120px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #5ca399;
  border: inherit;
  color: #d8d9dd;
  font-size: 12px;
  margin-left: 5px;
  font-weight: 700;
  cursor: pointer;
}

/* .button_followup {
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #5CA399;
  border: inherit;
  color: #D8D9DD;
  font-size: 8px;
  margin-left: 5px;
} */

.follow_up_div {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-right: 150px;
}

.follow_up_div_full {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  clear: both;
  float: right;
  width: 100%;
}

.vendor_submission {
  color: #a0a0a0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.wrap_inner_submission {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  color: #87888c;
}
.wrap_inner_submission .vendor_sub_boxes h3 {
  font-weight: normal;
  text-decoration: underline;
  font-size: 11px;
  margin-bottom: 15px;
}
.button_sub_box {
  margin-top: 20px;
  display: flex;
  gap: 5px;
}
.button_sub_box .reject_button {
  width: 120px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ac7676;
  font-size: 13px;
  border: inherit;
  color: #fff;
}
.button_sub_box .approve_button {
  width: 120px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #6caca1;
  font-size: 13px;
  color: #fff;
}

.NewRejBtn {
  width: 120px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ac7676;
  font-size: 13px;
  border: inherit;
  color: #fff;
  cursor: pointer;
  padding: 9px;
}

.NewRejBtn:hover {
  background: #955e5e;
}

.RejectInp {
  background-color: #272835;
  border: none;
  margin-right: 5px;
  padding-left: 10px;
  height: 30px;
}

.RejectInp::placeholder {
  text-transform: capitalize !important;
}

.mainDetailsReject {
  display: flex;
  width: 50%;
}

.DetailsReject {
  display: flex;
  width: max-content;
}

.VendSbmissionBox {
  display: flex;
  gap: 10px;
}

.VendSbmissionBox input {
  padding: 10px 15px;
  border-radius: 50px;
  outline: none;
  border: 1px solid #87888c;
  color: #87888c;
  background-color: transparent;
  width: 20vw;
}

.VendSbmissionBox button {
  background: #6ea9a1;
  color: rgba(225, 222, 222, 0.8);
  text-align: center;
  padding: 8px 20px;
  border: 1px solid #6ea9a1;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
  font-weight: bold;
}

.VendSbmissionBox button:hover {
  background: #36ac9c;
}

.reason_proof {
  background: #ffa1a1;
  margin-top: 20px;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  color: #fff;
}
::placeholder {
  text-transform: lowercase;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  text-transform: lowercase;
}
.wrap_budget_bar {
  margin-bottom: 20px;
}
.budget_right {
  width: 75%;
}
.buget_price {
  display: flex;
}

.budget_barrr {
  width: 80%;
  height: 16px;
  flex-shrink: 0;
  border-radius: 22px;
  background: #4b4a4a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  position: relative;
  box-sizing: border-box;
}
.bar_filled {
  content: "";
  border-radius: 22px;
  background: linear-gradient(180deg, #4c9fb1 0%, rgba(60, 250, 159, 0) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  padding: 8px;
}

.budget_details_customer {
  margin-top: 40px;
}
.update_list {
  float: right;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.prod_cats {
  color: #87888c;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.prod_cats:hover {
  color: #bebebe;
}
.categories_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 4px;
  width: 80%;
}

.products_main_Wrap {
  margin-top: 22px;
}

.products_left_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 80%;
}

.pro_box {
  border-radius: 5px;
  background: #21222d;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 18%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.pro_box:hover {
  background: #212222;
}

.image_pro_box {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.image_pro_box img {
  /* width: 100%; */
  height: 160px;
  box-sizing: border-box;
  object-fit: fill;
  margin-bottom: 5px;
}

.name_pro_box {
  color: #87888c;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 7px 0 7px 0;
  font-size: 13px;
  min-height: 32px;
}
.price_pro_box .amount_pro {
  color: #74757a;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 180% */
}
.stock_pro {
  color: #87beae;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.heart_icon_abs {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99;
}

.cart_box {
  width: 19%;
  height: calc(100vh - 17vh);
}
.cart_header {
  border-radius: 5px 5px 0px 0px;
  background: #5ca399;
  color: #d8d9dd;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}
.no_cart {
  text-align: center;
  margin-top: 30px;
}
.cart_inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 95%;
  box-sizing: border-box;
}
.cart_inner .button_followup {
  margin-left: 0;
}

.pro_detail_left {
  width: 20%;
}
.pro_detail_right {
  width: 80%;
}
.pro_detail_left .price_pro_box .amount_pro,
.pro_detail_left .price_pro_box .stock_pro {
  font-size: 13px;
  margin-top: 10px;
}
.name_pro_details {
  font-size: 13px;
  color: #87888c;
  margin-top: 10px;
}

.ncc {
  word-break: break-all;
  width: 110px;
}

.LoaderColor {
  color: red;
}

.MainProgressCart {
  width: 100%;
  height: 10px;
  border-radius: 50px;
  background-color: white;
  overflow: hidden;
}

.NoteKamVendorTmm {
  width: 100%;
}

.NoteBoxVen {
  display: flex;
  align-items: start;
  gap: 20px;
}

.NoteBoxVen p {
  margin: 0;
  color: #87888c;
  width: max-content;
}
.NoteTextKamVenarea {
  background-color: transparent;
  height: 60px;
  width: 95%;
  resize: none;
  padding: 10px 10px;
  outline: none;
  background-color: #272835;
  border: none;
  border-radius: 5px;
  font-size: 10px;
}

.NoteTextKamVenarea::placeholder {
  text-transform: none;
}

.NotePrevKamTmmVen {
  display: flex;
  gap: 20px;
}

.KmNoteVenHead {
  color: #87888c !important;
}

.NotePrevKamTmmVen p {
  color: #36ac9c;
}

.KamNoteBtn {
  width: 100px;
  padding: 8px;
  border-radius: 5px;
  background-color: #5ca399;
  color: white;
  border: none;
  font-size: 10px;
  cursor: pointer;
}

.KamNoteBtn:hover {
  background-color: #36ac9c;
}

.bran_input_cart {
  border-radius: 3px;
  background: #4d4f62;
  padding: 7px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-top: 10px;
  border: inherit;
}
.button_pro_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.button_pro_details button {
  width: 48%;
  height: 40px;
  flex-shrink: 0;
  border-radius: 3px;
  background: #5ca399;
  border: inherit;
  color: #d8d9dd;
  font-size: 12px;
  font-weight: bold;
}

.vendor_detai_pro {
  line-height: 20px;
  color: rgb(135, 136, 140);
  margin-top: 17px;
}

.image_four {
  width: 90%;
  margin: auto;
}
.desc_pro {
  margin: 20px 0 30px 0;
  color: #87888c;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.pro_sub_details {
  color: #87888c;
  display: flex;
  margin-bottom: 10px;
  line-height: 22px;
}
.pro_sub_details .heading_pro_left {
  width: 25%;
}
.demand_text {
  margin-top: 10px;
  color: #87888c;
}

.cart_items {
  background: rgba(77, 79, 98, 0.47);
  padding: 3px 10px;
  margin-bottom: 6px;
}
.item_count {
  background: #4d4f6240;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  color: #5780be;
  cursor: pointer;
  font-weight: bold;
}
.item_cart_data_confirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.item_cart_data_confirm button {
  width: 49%;
}
.cart_select {
  background: #4d4f62;
  padding: 5px;
  border: inherit;
  font-size: 10px;
  color: #c9c9c9;
}
.ml-0 {
  margin-left: 0 !important;
}

.bottom_final_amount {
  margin-top: 5%;
}
.mid_final_amount {
  background: #3c3d5145;
  width: 70%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}
.right_total_amot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
.right_total_amot .pro_sub_details {
  width: 450px;
  justify-content: flex-end;
}
.cart_line {
  border-bottom: 1px solid #87888c40;
  display: block;
  width: 40%;
}
.cart_selection {
  width: 25%;
}
.cart_all_buttons {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  width: 73%;
  margin: auto;
}
.sp_2 {
  margin-top: 20px;
  display: block;
}
.order_created_cart {
  margin-bottom: 13px;
  color: #a0a0a0;
}
.order_created_cart .first_ch {
  width: 120px;
  display: inline-block;
}
.custom_mar_auto {
  margin: auto;
  width: 70%;
}
.custom_big {
  width: 50% !important;
  background: #21222d !important;
}
.info_popup {
  color: #87888c;
}
.info_popup p {
  margin-top: 15px;
}
.popup_bespoke {
  width: 90%;
  margin: auto;
}

.customer_beskpoksss {
  color: #87888c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.customer_beskpoksss .besk1 {
  width: 65%;
}
.customer_beskpoksss span:last-child {
  display: block;
  text-align: right;
}
.beskpok_righ_cust {
  width: 50%;
}
.beskpok_righ_cust_left {
  width: 45%;
}

.popup_beskpok_button {
  display: flex;
  justify-content: flex-end;
}

.besk_detail_left {
  width: 30%;
}
.besk_detail_right {
  width: 70%;
}

.vendorrr_select {
  background: #4d4f62;
  padding: 5px;
  border: inherit;
  font-size: 11px;
  color: #c9c9c9;
  border-radius: 5px;
}

.besk_dot_status {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 12px;
}
.besk_big_heading {
  color: #a0a0a0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 20px;
  clear: both;
  display: block;
  float: left;
  margin-bottom: 10px;
}
.detaor_beskpoksss {
  color: #87888c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.besk_remarks {
  border: 1px solid #686871;
  padding: 10px;
  width: 100%;
  height: 80px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
}
.undomn {
  margin-top: 10px;
  font-size: 11px;
  display: block;
  color: #87888c;
}
.popup_button_slide {
  position: absolute;
  right: -13px;
  bottom: 0;
  top: 48px;
  width: 200px;
  z-index: 11;
}
.popup_button_slide_pro {
  position: absolute;
  right: -24px;
  bottom: 0;
  top: 48px;
  width: 200px;
  z-index: 11;
}
.inner_slid_btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #21222d;
  padding: 10px;
  float: left;
  border-radius: 10px;
}
.inner_slid_btn a {
  padding: 10px;
  border-bottom: 1px solid #363636;
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #d2d2d2;
  text-align: left;
}
.inner_slid_btn a:hover {
  color: var(--primary-color);
}
.inner_slid_btn a:last-child {
  border-bottom: none;
  padding-bottom: 5px;
}

.cart_tr_class {
  background: #3c3d51;
}
.item_count_dark {
  background: #31323f;
}
.dashboard_boxes_wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  flex-wrap: wrap;
}
.dashboard_boxes_wrap .card {
  width: 49%;
  box-sizing: border-box;
}
.dashboard_heading {
  color: #a0a0a0;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.smal_dash {
  color: #a0a0a0;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
.boxes_dashboard_first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.dash_box_left {
  background: #171821;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 25%;
  padding: 15px;
}
.dash_box_left img {
  margin-bottom: 12px;
}

.dash_box_left .count_stats {
  color: #87888c;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.dash_box_left .text_stats {
  color: #e8e8e8;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}
.dash_box_left .compare_stats {
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.orange {
  color: #feb95a;
}
.ferozie {
  color: #a9dfd8;
}
.pink {
  color: #f2c8ed;
}
.skyblue {
  color: #20aef3;
}
.notif_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 48%;
  margin-top: 7px;
  color: #87888c;
  font-size: 11px;
}
.notifx_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.time_notf {
  font-size: 8px;
}
.left_not {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}
.bottm_dash_wrap {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;
  flex-wrap: wrap;
}
.bottm_dash_wrap .card {
  width: 24%;
}
.larget_stats {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 8px;
}
.red {
  color: #d15356;
}
.greens {
  color: #3e824f;
}
.greys {
  color: #a6a6a6;
}
.small_stas {
  color: #87888c;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.meter {
  width: 80%;
  margin: auto;
  margin-top: -30px;
}
.meter canvas {
  width: 100%;
  margin-bottom: -50px;
}
.box_pro_per {
  width: 38px;
  height: 20.939px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #fcb859;
  background: rgba(252, 184, 89, 0.12);
  color: #fcb859;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 2px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order_bsss {
  color: #a0a0a0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.CardBoxMainProRight {
  position: relative;
}

.EditBoxPencil {
  position: absolute;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: #171821;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 15px;
}
.EditPencilIcon {
  font-size: 20px;
  color: #5ca399;
}

.button_pro_details button:hover,
.button_submit:hover,
.button_followup:hover,
.approve_button:hover,
.custom_button:hover {
  background: #29a392;
  border: inherit;
}
.reject_button:hover,
.button_sub_box .reject_button:hover {
  background: #be5a5a;
}
.pro_detail_count {
  float: right;
  margin-top: -23px;
}
.count_item {
  outline: none;
  border: none;
}

/* DARK MODE */
.darkmind {
  background: #cbcbcb;
  color: #54555b !important;
}
.darkmind .hedaer_title, 
.darkmind .navigation .heading, 
.darkmind .sub_nav .inner_nav, 
.darkmind .nav_main ,
.darkmind .name_pro_box,
.darkmind .prod_cats,
.darkmind .price_pro_box, 
.darkmind .desc_pro,
.darkmind .heading_pro_left,
.darkmind .pro_sub_details,
.darkmind .name_pro_details,
.darkmind .vendor_detai_pro,
.darkmind .inner_slid_btn a,

.darkmind input, .darkmind textarea, .darkmind select,
.darkmind .first_ch, .darkmind .order_created_cart,
.darkmind .table_wrap td,
.darkmind .tabs_green ,
.darkmind .setting_bar,
.darkmind .modal_heading,
.darkmind .undomn,
.darkmind .dash_box_left .count_stats,
/* .darkmind .larget_stats.greys, */
.darkmind .detaor_beskpoksss {
  color: #54555b;
}

.darkmind .table_wrap td {
  border-bottom: 0.5px solid #aeaeae !important;
}

.darkmind .larget_stats.greys {
  color: #7e7e88;
}
.darkmind .pro_box,
.darkmind .card,
.darkmind .search_bar,
.darkmind .create_button,
.darkmind .inner_slid_btn,
.darkmind .form-control,
.darkmind .setting_input,
.darkmind .image_browse_button,
.darkmind .dark_mode,
.darkmind .Cart_box_Pro {
  /* background: #CBCBCB; */
  background: #c0c2c6;
  border: inherit;
}

.darkmind .styled-table td {
  /* background: #CBCBCB; */
  background: #c0c2c6;
  border: #c0c2c6;
}

.darkmind .styled-table tbody tr {
  border-bottom: 1px solid #d3d3d3;
}
.darkmind .Cart_box_Pro:hover {
  background: #d3d3d3;
}
.darkmind .Pro_Card_add button,
.darkmind .EditBoxPencil {
  background: #d3d3d3;
}
.darkmind .create_button span {
  color: #54555b !important;
}

.darkmind .left_not {
  color: #54555b;
}
.darkmind .create_button svg path {
  fill: #54555b;
}
.darkmind .right_side_header img.cursor,
.darkmind .search_icon img,
.darkmind .dash_box_left img {
  filter: brightness(1) invert(01) grayscale(0);
}

.darkmind .drodown_dark,
.darkmind .nav_main img {
  filter: brightness(1) invert(01) grayscale(0) !important;
}

.darkmind .box_pro_per {
  color: #b36e0c;
  border: 1px solid #b36e0c;
}
.darkmind .cart_items {
  background: #d3d3d3;
  border: inherit;
}
.darkmind .dots::after {
  background-color: #7b1e1e !important;
}
.darkmind .light_bar_order {
  background: #b1b1b145;
}
.darkmind .lime_color {
  color: #eaff00;
}
.darkmind .nav_main_active {
  background: #a9e0d8;
  color: #54555b !important;
}
.darkmind .setting_bar svg path {
  stroke: #54555b;
}
.darkmind .popup_white_center {
  background: #8a8a8a;
}
.darkmind .bran_input_cart {
  color: #54555b;
  background: #dbdbdb;
}
.darkmind .black_table {
  background: #c3c0c0 !important;
}

.darkmind .beskop_set .setting_input,
.darkmind .beskop_set .image_browse_button {
  background: #d7d7d7;
}
.darkmind .custom_big {
  background: #d7d7d7 !important;
}
.darkmind .login_light .form-control,
.darkmind .cart_tr_class,
.darkmind .cart_select,
.darkmind .item_count_dark,
.darkmind .mid_final_amount,
.darkmind .vendorrr_select {
  background: #dadada;
}
.darkmind .dash_box_left {
  /* background: #dedede; */
  background: #cbcbcb;
  border: inherit;
}
.darkmind .dash_box_left .text_stats,
.darkmind .compare_stats,
.darkmind .dashboard_heading,
.darkmind .smal_dash {
  color: #54555b;
}

.ResetPOP {
  width: 30%;
}

.CartTextArea::placeholder {
  text-transform: capitalize;
}

/* // Products Style */

.Main_Add_Card_Box {
  padding-top: 5px;
  padding-bottom: 5px;
}

.AddCartBtnMain {
  width: 100%;
  padding: 8px;
  background-color: #5ca399;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.DelSelect {
  background-color: #f52020;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.btn_delete {
  background-color: #f52020;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  padding: 12px 15px;
  border: none;
}

.btn_delete:hover {
  background: #f52020b7;
}

.DelSelect:hover {
  background: #f52020b7;
  border: inherit;
}

.AddCartBtnMain:hover {
  background: #29a392;
  border: inherit;
}

.Pro_Card_add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.Pro_Card_add span {
  color: white;
}

.Pro_Card_add button {
  width: 40px;
  padding: 5px;
  background-color: #32333f;
  color: #74757a;
  cursor: pointer;
  border: none;
}

.Pro_Card_add button:nth-child(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.Pro_Card_add button:nth-child(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Cart_box_Pro {
  border-radius: 5px;
  background: #21222d;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 18%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.Cart_box_Pro:hover {
  background: #212222;
}

.OrderSidemain {
  display: flex;
}

.prevPic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.previewPicBeskope {
  width: 20%;
  height: 160px;
}

.sort-options {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

label {
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
}

select {
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #717175;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

#sortDropdown option {
  background-color: transparent !important;
  color: #000;
  font-size: 12px;
}

select:focus {
  border-color: #007bff;
}

.proselect {
  margin-top: 13px;
}

.checkbox-wrapper-15 .cbx {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
.checkbox-wrapper-15 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-15 .cbx span:first-child {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #b9b8c3;
  transition: all 0.2s ease;
}
.checkbox-wrapper-15 .cbx span:first-child svg {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 6px;
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-15 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #506eec;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition-delay: 0.2s;
}
.checkbox-wrapper-15 .cbx span:last-child {
  margin-left: 8px;
}
.checkbox-wrapper-15 .cbx span:last-child:after {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  height: 1px;
  width: 100%;
  background: #b9b8c3;
  transform-origin: 0 0;
  transform: scaleX(0);
}
.checkbox-wrapper-15 .cbx:hover span:first-child {
  border-color: #3c53c7;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child {
  border-color: #3c53c7;
  background: #3c53c7;
  animation: check-15 0.6s ease;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(2.2);
  opacity: 0;
  transition: all 0.6s ease;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child {
  color: #b9b8c3;
  transition: all 0.3s ease;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child:after {
  transform: scaleX(1);
  transition: all 0.3s ease;
}

@keyframes check-15 {
  50% {
    transform: scale(1.2);
  }
}

/* MEDIA QUERIS */

@media (max-width: 786px) {
  .ResetPOP {
    width: 50%;
  }

  .Cart_box_Pro {
    width: 30%;
  }

  .DetailsReject {
    gap: 10px;
    flex-wrap: wrap;
  }
  .mainDetailsReject {
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
  }
  .RejectInp {
    width: 235px;
  }
  .NewRejBtn {
    width: 249px;
  }

  .button_followup {
    width: 250px;
    margin-left: 0;
  }
  .boxes_dashboard_first {
    flex-wrap: wrap;
  }
  .dash_box_left {
    width: 46%;
    box-sizing: border-box;
  }
  .bottm_dash_wrap .card,
  .notif_div {
    width: 100%;
  }
  .new_desktop {
    display: none;
  }
  .mobile_view {
    display: block;
    width: 100%;
  }
  .left_bar_fixed {
    display: none;
  }
  .content_inner {
    padding-left: 0;
  }
  .right_side_bar {
    margin-left: 0;
    width: 100%;
    margin-right: 0;
  }
  .flex-space-between-stretch {
    flex-direction: column;
  }
  .products_left_wrap {
    width: 100%;
  }
  .cart_box {
    width: 100%;
    margin-top: 20px;
  }
  .pro_box {
    width: 48%;
  }
  .Pro_box_pro {
    width: 48%;
  }
  .categories_list {
    display: flex;
    flex-wrap: wrap;
  }
  .top_logo_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile_view_p .left_bar_fixed {
    display: block;
    width: 65%;
    background: #171821;
  }
  .darkmind .left_bar_fixed {
    background: #f0f0f0;
  }
  .pro_detail_left,
  .pro_detail_right,
  .mid_final_amount,
  .cart_selection,
  .cart_line {
    width: 100%;
    box-sizing: border-box;
  }
  .image_four {
    flex-wrap: wrap;
    width: 100%;
  }
  .responsive_table {
    width: 100% !important;
    overflow: scroll;
  }
  .mid_final_amount .flex-space-between-start,
  .checkout_bottom_btns .flex-space-between-start {
    flex-direction: column;
  }
  .right_total_amot {
    width: 100%;
    margin-top: 30px;
  }
  .right_total_amot .pro_sub_details {
    width: 100%;
    justify-content: flex-start;
  }
  .pro_sub_details .heading_pro_left {
    width: 80%;
  }
  .responsive_table table {
    width: 1000px !important;
  }
  .checkout_bottom_btns .p20 {
    width: 100%;
    box-sizing: border-box;
  }
  .tabs_green {
    width: 47%;
    margin-bottom: 5px;
  }
  .graph_view,
  .flex_wrap_box_order_vendor .left_order_box,
  .box_order_inner {
    width: 100%;
  }
  .dots_outer .dots:last-child {
    margin-right: 0;
  }
  .flex_wrap_box_order_vendor,
  .left_order_box {
    flex-direction: column;
  }
  .follow_up_div {
    justify-content: center;
    margin-right: 0;
  }
  .custom_mar_auto {
    width: 100%;
  }
  .image_upload_flex {
    flex-wrap: wrap;
    gap: 0px 11px;
  }
  .image_browse_button {
    width: 48%;
    box-sizing: border-box;
  }
  .content_settings {
    flex-direction: column;
  }
  .left_setting_bar,
  .right_settings_box {
    width: 100%;
    box-sizing: border-box;
  }
  .popup_white_center {
    width: 95%;
  }
  .wrap_budget_bar {
    flex-direction: column;
    width: 100%;
  }
  .budget_left {
    width: 100%;
    margin-bottom: 20px;
  }
  .card.p40 {
    padding: 20px;
  }
  .wrapper-full {
    width: 95%;
    margin: auto;
  }
  .content_settings.p30 {
    padding: 15px;
  }
  .wrap_inner_submission {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
  .budget_right {
    width: 100%;
  }
  .customer_mov {
    flex-direction: column;
    margin-top: 15px;
  }
  .budget_barrr,
  .buget_price {
    width: 100%;
  }
  .buget_price {
    text-align: left;
  }
  .dashboard_boxes_wrap .card {
    width: 100%;
  }
  .login_light .card,
  .m-auto-60,
  .custom_big {
    width: 90% !important;
  }
  .custom_big img {
    width: 24%;
  }
  .beskpoke_customer.flex-space-between {
    flex-direction: column;
  }
  .beskpok_righ_cust_left {
    width: 100%;
  }
  .beskpok_righ_cust {
    width: 100%;
    margin-top: 20px;
  }
  .products_main_Wrap {
    flex-direction: column;
  }
  .besk_detail_right,
  .besk_detail_left {
    width: 100%;
  }
  .popup_beskpok_button {
    flex-wrap: wrap;
  }
}

.customer_logo {
  text-align: center;
  margin-bottom: 35px;
  margin-top: -5px;
}

.customer_logo img {
  width: 150px;
}
.loader {
  padding: 10%;
  text-align: center;
  width: 100%;
}

.image_upl_file img {
  width: 92px;
  height: 92px;
  object-fit: contain;
  border-radius: 18px;
}
.no_data {
  text-align: center !important;
  color: #ac7676 !important;
}
.table_wrap img {
  width: 28px;
  height: 28px;
  border-radius: 50px;
}

.header_img_us .img {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}

.image_four img {
  width: 140px;
  height: 140px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.products_left_wrap .no_data {
  padding: 10%;
  width: 100%;
}

.prod_cats.selected {
  text-decoration: none;
  color: #fff;
}

.logo img {
  height: 35px;
  margin-top: 15px;
}
.product_data {
  display: block;
  margin-bottom: 25px;
}
.product_data:last-child {
  margin-bottom: 0px !important;
}

.no_text_found {
  color: red;
  text-align: center;
}

.blue {
  color: #5780be;
}

.pills_wrap_flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.pills_user_cars {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid #ccc;
  text-align: center;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
}

.bar_abs {
  position: absolute;
  content: "|";
  color: #fff;
  font-size: 20px;
  height: 46px;
  width: 2px;
  padding: 0;
  top: -60px;
  right: 5px;
  transform: rotate(19deg);
}

.no_text_cnter {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4646;
}

.dashboard_responsive .product_data {
  margin-bottom: 12px;
}

input[type="date"] {
  text-transform: uppercase;
}

.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.left0 {
  left: 7px !important;
}

.delete_color {
  color: rgb(245, 32, 32);
  cursor: pointer;
}

.darkmind .history_wrap {
  border: 1px solid #d7d2d2;
}
.history_wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  border: 1px solid #292a36;
  padding: 10px;
  border-radius: 10px;
}
.history_wrap:last-child {
  margin-bottom: 0px !important;
}
.history_number {
  background: var(--primary-color);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 14px;
  width: 14px;
  border-radius: 100px;
  font-weight: bold;
}
.content_history span {
  color: red;
}
.history_date {
  font-size: 10px;
  color: #7c7474;
  margin-top: 5px;
}

.redcolor {
  background: #a83939 !important;
}

.only_admin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 30vh);
  color: #ff8686;
}

@media screen and (max-width: 600px) {
  .ResetPOP {
    width: 80%;
  }
  .Cart_box_Pro {
    width: 48%;
  }
}
